//
// Typography
// --------------------------------------------------

// Cursive (Pacifico)
.fontCursive {
  font-family: @font-family-cursive;
  font-weight: normal;
}

// San serif (Titillium Web)
.fontLight {
  font-family: 'titillium_weblight', @font-family-sans-serif;
  font-weight: normal;
}
.fontRegular {
  font-family: 'titillium_webregular', @font-family-sans-serif;
  font-weight: normal;
}
.fontItalic {
  font-family: 'titillium_webitalic', @font-family-sans-serif;
  font-style: normal;
  font-weight: normal;
}
.fontSemibold {
  font-family: 'titillium_websemibold', @font-family-sans-serif;
  font-weight: normal;
}
.fontBold {
  font-family: 'titillium_webbold', @font-family-sans-serif;
  font-weight: normal;
}
.fontBoldItalic {
  font-family: 'titillium_webbold_italic', @font-family-sans-serif;
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'titillium_webbold';
  src: url('../fonts/titillium_web/titilliumweb-bold-webfont.woff2') format('woff2'),
       url('../fonts/titillium_web/titilliumweb-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'titillium_webbold_italic';
  src: url('../fonts/titillium_web/titilliumweb-bolditalic-webfont.woff2') format('woff2'),
       url('../fonts/titillium_web/titilliumweb-bolditalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'titillium_webitalic';
  src: url('../fonts/titillium_web/titilliumweb-italic-webfont.woff2') format('woff2'),
       url('../fonts/titillium_web/titilliumweb-italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'titillium_weblight';
  src: url('../fonts/titillium_web/titilliumweb-light-webfont.woff2') format('woff2'),
       url('../fonts/titillium_web/titilliumweb-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'titillium_webregular';
  src: url('../fonts/titillium_web/titilliumweb-regular-webfont.woff2') format('woff2'),
       url('../fonts/titillium_web/titilliumweb-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'titillium_websemibold';
  src: url('../fonts/titillium_web/titilliumweb-semibold-webfont.woff2') format('woff2'),
       url('../fonts/titillium_web/titilliumweb-semibold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'pacificoregular';
  src: url('../fonts/pacifico/pacifico-webfont.woff2') format('woff2'),
       url('../fonts/pacifico/pacifico-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

// HEADINGS
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  color: #4a4a4a;
  .fontBold;
  line-height: 1.3;
  margin: 0 0 (@base-line-height / 3);

  small,
  .small {
    font-weight: normal;
    line-height: 1;
  }
}

h1, .h1 {
  font-size: 2.0588235294rem;
}
h2, .h2 {
  font-size: 1.5294117647rem;
}
h3, .h3 {
  font-size: 1.4117647059rem;
}
h4, .h4 {
  font-size: 1.1764705882rem;
}
h5, .h5 {
  font-size: 1rem;
}
h6, .h6 {
  font-size: 1rem;
}

// BODY
p {
  color: @text-color;
  .fontRegular;
  font-size: 1rem;
  margin: 0 0 (@base-line-height);
  &.intro {
    .fontBold;
    em {
      .fontBoldItalic;
    }
  }
  &.intro {
    color: @color-secondary;
  }
}

b,
strong {
  .fontBold;
}
em {
  .fontItalic;
}
strong em,
em strong {
  .fontBoldItalic;
}

// LISTS
ul, ol, dl {
  .fontRegular;
  font-size: 1rem;
  margin: 0 0 (@base-line-height);
  ul, ol, dl {
    margin-bottom: 0;
  }
}

// MISC
small,
.small {
  font-size: 80%;
}

mark,
.mark {
  background-color: @color-focus;
  padding: .2em;
}

.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}
.text-nowrap {
  white-space: nowrap;
}
.text-lowercase {
  text-transform: lowercase;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
}